import React from 'react';
import {useFetchVehiclesByCountryQuery} from "~/api/controlDevice/ControlDevice.api";
import StatsWidgets from "@rio-cloud/rio-uikit/StatsWidgets";
import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import StatsWidgetNumber from "@rio-cloud/rio-uikit/StatsWidgetNumber";
import StatsWidgetSpacer from "@rio-cloud/rio-uikit/StatsWidgetSpacer";

interface OverviewDataSummaryProps {}

const OverviewDataSummary: React.FC<OverviewDataSummaryProps> = () => {

    const { data } = useFetchVehiclesByCountryQuery({});

    console.log(data)

    return (
        <div className="overview-map">
{/*
            <div className="display-grid margin-top-20 gap-20 grid-cols-12">
*/}
                {/* Map Section */}

                {/* Cards Section */}
                <div className=" bg-white">

                    <div className="display-flex">
                        <div className="bg-white">
                            <StatsWidgets className='margin-bottom-20'>
                                <StatsWidget style={{ border: "none" }} >
                                    <StatsWidget.Header><h5 style={{ fontFamily: 'MANEUROPECONDENSED' }}>Total Vehicles</h5></StatsWidget.Header>
                                    <StatsWidget.Body>
                                        <StatsWidgetNumber className='text-color-primary' value={'600 000'}/>
                                    </StatsWidget.Body>
                                </StatsWidget>

                            </StatsWidgets>
                        </div>
                        <div className="bg-white">
                            <StatsWidgets className=''>
                                <StatsWidget  style={{ border: "none" }} >
                                    <StatsWidget.Header><h5 style={{ fontFamily: 'MANEUROPECONDENSED' }}>Types Devices in Vehicles</h5></StatsWidget.Header>
                                    <StatsWidget.Body>
                                        <StatsWidgetNumber className='text-color-status-driving' value={'100 000'} label='CM4' />

                                        <StatsWidgetSpacer />

                                        <StatsWidgetNumber className='text-color-status-driving' value={'350 000'} label='TBM3' />

                                        <StatsWidgetSpacer />

                                        <StatsWidgetNumber className='text-color-status-driving' value={'100 000'} label='VCM' />
                                    </StatsWidget.Body>
                                </StatsWidget>
                            </StatsWidgets>
                        </div>
                        <div className="bg-white">
                            <StatsWidgets className=''>
                                <StatsWidget style={{ border: "none" }} >
                                    <StatsWidget.Header><h5 style={{ fontFamily: 'MANEUROPECONDENSED' }}>Update Events</h5></StatsWidget.Header>
                                    <StatsWidget.Body>
                                        <StatsWidgetNumber className='text-color-success' value={'3 000 000'} label='Success' />
                                        <StatsWidgetSpacer />
                                        <StatsWidgetNumber className='text-color-danger' value={'200 000'} label='Failed' />
                                    </StatsWidget.Body>
                                </StatsWidget>
                            </StatsWidgets>
                        </div>
                        <div className='bg-white'>
                            <StatsWidgets className=''>
                                <StatsWidget  style={{ border: "none" }} >
                                    <StatsWidget.Header>            <div className=' text-start text-size-h5 text-bold'
                                                                         style={{ fontFamily: 'MANEUROPECONDENSED' }}>{'Variants'}</div>
                                    </StatsWidget.Header>
                                    <StatsWidget.Body>
                                        <StatsWidgetNumber className='text-color-status-driving' value={'168k+'} label='TG3' />
                                        <StatsWidgetSpacer />
                                        <StatsWidgetNumber className='text-color-status-driving' value={'2k+'} label='FMS' />
                                        <StatsWidgetSpacer />
                                        <StatsWidgetNumber className='text-color-status-driving' value={'20k+'} label='Elsa' />
                                        <StatsWidgetSpacer />
                                        <StatsWidgetNumber className='text-color-status-driving' value={'25k+'} label='Core' />
                                    </StatsWidget.Body>
                                </StatsWidget>
                            </StatsWidgets>

                        </div>
                    </div>
                </div>
{/*
            </div>
*/}
        </div>
    );

};

export default OverviewDataSummary;
