import { createApi, } from '@reduxjs/toolkit/query/react';
import { dynamicBaseQuery } from '~/api/common/dynamicBaseQuery';
import { ResponseValueListString } from '~/api/models/response-value-list-string';

const baseUrl: string = '/v1/admin/control-device';
export const controlDeviceApi = createApi({
    reducerPath: 'controlDeviceApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        fetchHwVariants: builder.query<string[], {}>({
            query: () => ({
                url: `${baseUrl}/hardware-variants`,
                method: 'GET',
            }),
            transformResponse: (data: ResponseValueListString) => {
                return data.content;
            },
        }),
        fetchStatusTypes: builder.query<string[], {}>({
            query: () => ({
                url: `${baseUrl}/status-types`,
                method: 'GET',
            }),
            transformResponse: (data: ResponseValueListString) => data.content,
        }),
        fetchSparePartNumbers: builder.query<string[], {}>({
            query: () => ({
                url: `${baseUrl}/spare-part-numbers`,
                method: 'GET',
            }),
            transformResponse: (data: ResponseValueListString) => data.content,
        }),
        fetchStatusName: builder.query<string[], {}>({
            query: () => ({
                url: `${baseUrl}/status-name`,
                method: 'GET',
            }),
            transformResponse: (data: ResponseValueListString) => data.content,
        }),
        fetchEligibleBundle: builder.query<any, { serialNumber: string }>({
            query: (params: { serialNumber: string }) => ({
                url: `${baseUrl}/${params.serialNumber}/eligible/bundle-versions`,
                method: 'GET',
            }),
        }),
        fetchVehicleVariants: builder.query<any, {}>({
            query: () => ({
                url: `${baseUrl}/vehicle-variants`,
                method: 'GET',
            }),
            transformResponse: (data: ResponseValueListString) => data.content.filter(v => v !== ''),
        }),
        fetchVehiclesByCountry: builder.query<any, any>({
            query: () => ({
                url: `${baseUrl}/counts-by/country-network-code`,
                method: 'POST',
                body:{}
            }),
           // transformResponse: (data: ResponseValueListString) => data.content.filter(v => v !== ''),
        })
    }),
});

export const {
    useFetchHwVariantsQuery,
    useFetchStatusTypesQuery,
    useFetchStatusNameQuery,
    useFetchSparePartNumbersQuery,
    useFetchEligibleBundleQuery,
    useFetchVehicleVariantsQuery,
    useFetchVehiclesByCountryQuery
} = controlDeviceApi;
