import React from 'react';
import { useFetchALLNavimapRegionsQuery } from '~/api/deliverables/DeliverablesManagement.api';
import { UNKNOWN } from '~/features/base/constants/filterOptions';

interface NavimapRegionProps {
    code: string;
}

const NavimapRegion: React.FC<NavimapRegionProps> = ({
    code
}) => {
    const { data: regions } = useFetchALLNavimapRegionsQuery({});

    const getLabel = () => {
        if (regions && regions[code]) {
            return `${code} - ${regions[code]}`;
        }
        return UNKNOWN;
    };

    return (
        <h6>
            {getLabel()}
        </h6>
    );
};

export default NavimapRegion;
