import { createApi, } from '@reduxjs/toolkit/query/react';
import { dynamicBaseQuery } from '~/api/common/dynamicBaseQuery';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { ResponseValueListSemanticVersion } from '~/api/models/response-value-list-semantic-version';
import { DeliverableSearchCriteria } from '~/api/models/deliverable-search-criteria';
import { PageDeliverable } from '~/api/models/page-deliverable';
import { SemanticVersion } from '~/api/models';
import { PersistedDeliverable } from '~/api/models/persisted-deliverable';
import { DeliverablesRolloutOverview } from '~/api/models/deliverables-rollout-overview';
import { RolloutOverviewItem } from '~/api/models/rollout-overview-item';
import { deliverablesTypes } from '~/features/overviewNew/components/RolloutsTile';
import { DeliverableBody } from '~/api/models/deliverable-body';
import { NavimapRegion } from '~/api/models/navimap-region';

const baseUrl: string = '/v1/admin/deliverable';

export const deliverableManagementApi = createApi({
    reducerPath: 'deliverableManagementApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        fetchBaseSwVersions: builder.query<{ shortBaseSwVersion: string }[], {}>({
            query: () => ({
                url: `${baseUrl}/base-software-versions`,
                method: 'GET',
            }),
            transformResponse: (data: ResponseValueListSemanticVersion) => data.content.map((version) => {
                return { shortBaseSwVersion: toShortSemanticVersion(version) };
            })
        }),
        fetchAllDistroVersions: builder.query<{ shortDistroVersion: string }[], DeliverableSearchCriteria>({
            query: () => ({
                url: `${baseUrl}/search?page=0&size=1000`,
                method: 'POST',
                body: { deliverableType: DeliverableType.DISTRO }
            }),
            transformResponse: (data: PageDeliverable) => data.content.map((deliverable) => {
                return { shortDistroVersion: toShortSemanticVersion(deliverable.deliverableVersion) };
            })
        }),
        fetchDeliverableVersions: builder.query<{ content: SemanticVersion[] }, DeliverableVersionRequestModel>({
            query: ({ deliverableType, deliverableId }) => ({
                url: `${baseUrl}/${deliverableType}/${deliverableId}/versions`,
                method: 'GET',
            }),
        }),
        fetchDeliverableIds: builder.query<{ content: string[] }, DeliverableSearchCriteria>({
            query: (criteria: DeliverableSearchCriteria) => ({
                url: `${baseUrl}/search/deliverable-ids?page=0&size=1000`,
                method: 'POST',
                body: criteria
            }),
        }),
        updateDeliverable: builder.query<{ content: string[] }, DeliverableSearchCriteria>({
            query: (criteria: DeliverableSearchCriteria) => ({
                url: `${baseUrl}/search/deliverable-ids?page=0&size=1000`,
                method: 'PUT',
                body: criteria
            }),
        }),

        validateDeliverable: builder.query<{ content: string[] }, {
            deliverable: PersistedDeliverable,
            isEdit: boolean
        }>({
            query: (body) => ({
                url: `${baseUrl}/validate-for-create`,
                method: 'POST',
                body: body.deliverable
            }),
            serializeQueryArgs: ({ queryArgs: { deliverable, isEdit } }) => ({
                deliverableId: deliverable?.deliverableId,
                deliverableVersion: deliverable?.deliverableVersion,
                isEdit
            }),
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),

        fetchActiveRollouts: builder.query<Array<RolloutOverviewItem>, {}>({
            query: () => ({
                url: `${baseUrl}/rollouts-overview`,
                method: 'GET',
            }),
            transformResponse: (data: DeliverablesRolloutOverview) => {
                return data.rollouts?.sort((a, b) => {
                    return deliverablesTypes.indexOf(a.deliverableType) - deliverablesTypes.indexOf(b.deliverableType);
                }) || [];
            }
        }),
        fetchDeliverableSubtype: builder.query<{subtype: string}, DeliverableBody>({
            query: (body) => ({
                url: `${baseUrl}/deliverable-sub-type`,
                method: 'POST',
                body: body,
            }),
        }),
        fetchALLNavimapRegions: builder.query<{ [key: string]: string }, {}>({
            query: () => ({
                url: `/v1/admin/navimap/regions`,
                method: 'GET',
            }),
            transformResponse: (data: NavimapRegion[]) => {
                return data.reduce((result, item) => {
                    result[item.code] = item.description;
                    return result;
                }, {} as { [key: string]: string });
            }
        }),

    }),
});

export const {
    useFetchBaseSwVersionsQuery,
    useFetchAllDistroVersionsQuery,
    useLazyFetchDeliverableVersionsQuery,
    useFetchDeliverableIdsQuery,
    useValidateDeliverableQuery,
    useLazyValidateDeliverableQuery,
    useFetchActiveRolloutsQuery,
    useFetchDeliverableSubtypeQuery,
    useLazyFetchDeliverableSubtypeQuery,
    useFetchALLNavimapRegionsQuery,
} = deliverableManagementApi;
