import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

interface DeviceStateSummaryItemProps {
    label: string,
    badge: string,
    message: string | ReactNode,
}

const DeviceStateSummaryItem: React.FC<DeviceStateSummaryItemProps> = ({
    label,
    badge,
    message
}: DeviceStateSummaryItemProps) => {
    const textRef = useRef<HTMLDivElement | null>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const element = textRef.current;
        if (element) {
            setIsTruncated(element.scrollWidth > element.offsetWidth);
        }
    }, [message]);

    const fullMessage = <div className={'ellipsis-1'} ref={textRef}>
        <span className={`text-size-16 margin-right-10 ${badge}`}/>
        <FormattedMessage id={label}/>:
        <span className='margin-left-5 ellipsis-1'>
            {message}
        </span>
    </div>;

    return (
        <>
            {isTruncated ? (
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip id='tooltip' className='top-right'>
                            {message}
                        </Tooltip>
                    }
                >
                    {fullMessage}
                </OverlayTrigger>
            ) : (
                fullMessage
            )}
        </>
    );
};

export default DeviceStateSummaryItem;


