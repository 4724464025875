import React from 'react';
import { useFetchALLNavimapRegionsQuery } from '~/api/deliverables/DeliverablesManagement.api';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { FormattedMessage } from 'react-intl';

interface NaviMapRegionsSelectProps {
    onChange: (value) => void;
    value: string;
}

const NaviMapRegionsSelect: React.FC<NaviMapRegionsSelectProps> = ({
    value, onChange,
}) => {
    const { data: regions } = useFetchALLNavimapRegionsQuery({});
    const regionsOptions = regions && Object.keys(regions).map((code) => ({
        id: code,
        label: `${code} - ${regions[code]}`,
        value: code,
    }));
    return (
        <div className='flex-basis-100pct'>
            <label className='control-label'><FormattedMessage
                id='Region'/></label>
            <Select
                placeholder={'Please select a region...'}
                useFilter
                useClear
                options={regionsOptions || []}
                value={[value]}
                onChange={(value) => onChange(value.id)}
            />
        </div>
    );
};

export default NaviMapRegionsSelect;
