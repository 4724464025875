import React from 'react';
import VehicleVariantsSelect from '~/features/overviewNew/components/VehicleVariantsSelect';
import OverviewFooter from '~/features/overviewNew/components/OverviewFooter';
import AdditionalInfo from '~/features/overviewNew/components/AdditionalInfo';
import AllVehiclesInfo from "~/features/overviewNew/components/AllVehiclesInfo";
import OverviewDataSummary from "~/features/overviewNew/components/OverviewDataSummary";

interface OverviewContainerProps {

}

const OverviewContainer: React.FC<OverviewContainerProps> = () => {

    return (
        <div className="overview">
{/*
            <VehicleVariantsSelect/>
*/}
            <OverviewDataSummary/>
            <AllVehiclesInfo/>
            <OverviewFooter/>
        </div>
    );
};

export default OverviewContainer;
