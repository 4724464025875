import React from 'react';
import StatsWidgets from '@rio-cloud/rio-uikit/StatsWidgets';
import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import StatsWidgetNumber from '@rio-cloud/rio-uikit/StatsWidgetNumber';
import StatsWidgetSpacer from '@rio-cloud/rio-uikit/StatsWidgetSpacer';
import {useFetchVehiclesByCountryQuery} from "~/api/controlDevice/ControlDevice.api";
import OverviewMap from "~/features/overviewNew/components/OverviewMap";
import RolloutsTile from "~/features/overviewNew/components/RolloutsTile";

interface AllVehiclesInfoProps {}

const AllVehiclesInfo: React.FC<AllVehiclesInfoProps> = () => {

    const { data } = useFetchVehiclesByCountryQuery({});

    console.log(data)

    return (
        <div className="overview-map">
            <div className="display-grid margin-top-20 gap-20 grid-cols-12">
                {/* Map Section */}
                <div className="padding-20 grid-colspan-6 bg-white">
                    <OverviewMap/>
                </div>

                {/* Cards Section */}
                <div className="padding-20 grid-colspan-6 bg-white">
                    <RolloutsTile/>

                    {/*<div className="display-grid gap-20 grid-cols-2">
                         Top Row: Two cards side by side
                        <div className="bg-white padding-20">
                            <StatsWidgets className='margin-bottom-20'>
                                <StatsWidget style={{ border: "none" }} >
                                    <StatsWidget.Header><h5 style={{ fontFamily: 'MANEUROPECONDENSED' }}>Total Vehicles</h5></StatsWidget.Header>
                                    <StatsWidget.Body>
                                        <StatsWidgetNumber className='text-color-primary' value={'600 000'}/>
                                    </StatsWidget.Body>
                                </StatsWidget>

                            </StatsWidgets>
                        </div>
                        <div className="bg-white padding-20">
                            <StatsWidgets className='margin-bottom-20'>
                                <StatsWidget  style={{ border: "none" }} >
                                    <StatsWidget.Header><h5 style={{ fontFamily: 'MANEUROPECONDENSED' }}>Types Devices in Vehicles</h5></StatsWidget.Header>
                                    <StatsWidget.Body>
                                        <StatsWidgetNumber className='text-color-status-driving' value={'100 000'} label='CM4' />

                                        <StatsWidgetSpacer />

                                        <StatsWidgetNumber className='text-color-status-driving' value={'350 000'} label='TBM3' />

                                        <StatsWidgetSpacer />

                                        <StatsWidgetNumber className='text-color-status-driving' value={'100 000'} label='VCM' />
                                    </StatsWidget.Body>
                                </StatsWidget>
                            </StatsWidgets>
                        </div>
                    </div>
                    <div className="bg-white padding-20 margin-top-20">
                        <StatsWidgets className='margin-bottom-20'>
                            <StatsWidget style={{ border: "none" }} >
                                <StatsWidget.Header><h5 style={{ fontFamily: 'MANEUROPECONDENSED' }}>Update Events</h5></StatsWidget.Header>
                                <StatsWidget.Body>
                                    <StatsWidgetNumber className='text-color-success' value={'3 000 000'} label='Success' />
                                    <StatsWidgetSpacer />
                                    <StatsWidgetNumber className='text-color-danger' value={'200 000'} label='Failed' />
                                </StatsWidget.Body>
                            </StatsWidget>
                        </StatsWidgets>
                    </div>*/}
                </div>
            </div>
        </div>
    );

};

export default AllVehiclesInfo;
