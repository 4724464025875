// This is the runtime configuration being used for accessing
// production data from a local frontend build

const authority = 'https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2';
const oauthService = `${authority}/oauth2/v2.0`;

export const localProdConfig = {
    id: 'env.localprod',
    region: 'EU',
    backend: {
        AUTHENTICATION_SERVICE: oauthService,
        DEVICE_MANAGEMENT_SERVICE: 'https://ota-service.device-ota-updates.rio.cloud/api/mdmservice',
        DEVICE_SYSTEM_STATE_SERVICE: 'https://system-state.device-ota-updates.rio.cloud/api/tbm3systemstatenotification',
        DELIVERABLE_MANAGEMENT_SERVICE: 'https://deliverable-mgmt.device-ota-updates.rio.cloud',
        DEVICE_LOG_DOWNLOAD_SERVICE: 'https://log-service.device-ota-updates.rio.cloud/api/tbm3log/v1/devices',
        LOG_SERVICE: 'https://mdm-logging-service.device-ota-updates.rio.cloud/api/mdm/logging',
        ATLASSIAN_CONNECTOR: 'https://atlassian-connector.device-ota-updates.rio.cloud/api/mdmotaac',
        REPORTS_BILLING_CHARTS_DATA_SERVICE: 'https://mdm-reporting-service.device-ota-updates.rio.cloud/api/mdm/reporting/v1/traffic/search',
        REPORTS_BILLING_CHARTS_DATA_VERSIONS_SERVICE: 'https://6182843e84c2020017d89efa.mockapi.io/dataTraffic/dataTrafficTypes',
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: 'https://admin-eu.rio.cloud/#app-menu',
    userMenuUri: 'https://admin-eu.rio.cloud/#user-menu',
    login: {
        authority: 'https://auth.iam.rio.cloud',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'user-managment.read',
        ],
        mockAuthorization: true,
        preventRedirect: false,
        redirectUri: 'https://mdm.rio.cloud/redirect.html',
        silentRedirectUri: 'https://mdm.rio.cloud/redirect.html',
    },
    logoutUri: `${oauthService}/logout?redirect_uri=https://admin-eu.rio.cloud`,
    downloadBaseUrl: 'https://transfer.devgateway.eu/release/',
    downloadCM4GUrl: 'https://transfer.cm4.devgateway.eu/release/cm4g/MDM/',
    packageVersionInstallerBaseUrl: 'https://transfer.devgateway.eu/release/packages/app-installer_2020.09.04',
    showAdaptOrder: true,
    mapScale: { scale: 1200, center: [10, 50] },
    networkCodes: {
            0: { name: 'Unknown', iso2: 'xx', coordinates: [0.0, 0.0] }, //used if network code is '000' || ''
            202: { name: 'Greece', iso2: 'gr', coordinates: [22.9566, 39.0742] },
            204: { name: 'Netherlands', iso2: 'nl', coordinates: [5.2913, 52.1326] },
            206: { name: 'Belgium', iso2: 'be', coordinates: [4.4699, 50.5039] },
            208: { name: 'France', iso2: 'fr', coordinates: [2.2137, 46.6034] },
            212: { name: 'Monaco', iso2: 'mc', coordinates: [7.4246, 43.7384] },
            213: { name: 'Andorra', iso2: 'ad', coordinates: [1.5211, 42.5078] },
            214: { name: 'Spain', iso2: 'es', coordinates: [-3.7038, 40.4168] },
            216: { name: 'Hungary', iso2: 'hu', coordinates: [19.5033, 47.1625] },
            218: { name: 'Bosnia and Herzegovina', iso2: 'ba', coordinates: [17.6791, 43.9159] },
            219: { name: 'Croatia', iso2: 'hr', coordinates: [15.2, 40.1] },
            220: { name: 'Serbia', iso2: 'rs', coordinates: [21.0059, 44.0165] },
            222: { name: 'Italy', iso2: 'it', coordinates: [12.5674, 41.8719] },
            225: { name: 'Vatican City State', iso2: 'xx', coordinates: [12.4534, 41.9029] },
            226: { name: 'Romania', iso2: 'ro', coordinates: [24.9668, 45.9432] },
            228: { name: 'Switzerland', iso2: 'ch', coordinates: [8.2275, 46.8182] },
            230: { name: 'Czech Republic', iso2: 'cz', coordinates: [15.4729, 49.8175] },
            231: { name: 'Slovakia', iso2: 'sk', coordinates: [19.699, 48.669] },
            232: { name: 'Austria', iso2: 'at', coordinates: [14.5501, 47.5162] },
            234: { name: 'United Kingdom', iso2: 'gb', coordinates: [-3.436, 55.3781] },
            235: { name: 'United Kingdom', iso2: 'gb', coordinates: [-3.436, 55.3781] },
            238: { name: 'Denmark', iso2: 'dk', coordinates: [9.5018, 56.2639] },
            240: { name: 'Sweden', iso2: 'se', coordinates: [18.6435, 60.1282] },
            242: { name: 'Norway', iso2: 'no', coordinates: [8.4689, 60.472] },
            244: { name: 'Finland', iso2: 'fi', coordinates: [25.7482, 61.9241] },
            246: { name: 'Lithuania', iso2: 'lt', coordinates: [23.8813, 55.1694] },
            247: { name: 'Latvia', iso2: 'lv', coordinates: [24.6032, 56.8796] },
            248: { name: 'Estonia', iso2: 'ee', coordinates: [25.0136, 58.5953] },
            250: { name: 'Russian Federation', iso2: 'ru', coordinates: [105.3188, 61.524] },
            255: { name: 'Ukraine', iso2: 'ua', coordinates: [31.1656, 48.3794] },
            257: { name: 'Belarus', iso2: 'by', coordinates: [27.9534, 53.7098] },
            259: { name: 'Moldova', iso2: 'md', coordinates: [28.3699, 47.4116] },
            260: { name: 'Poland', iso2: 'pl', coordinates: [19.1451, 51.9194] },
            262: { name: 'Germany', iso2: 'de', coordinates: [10.4515, 51.1657] },
            266: { name: 'Gibraltar (UK)', iso2: 'gi', coordinates: [-5.3454, 36.1408] },
            268: { name: 'Portugal', iso2: 'pt', coordinates: [-8.2245, 39.3999] },
            270: { name: 'Luxembourg', iso2: 'lu', coordinates: [6.1296, 49.8153] },
            272: { name: 'Ireland', iso2: 'ie', coordinates: [-7.6921, 53.1424] },
            274: { name: 'Iceland', iso2: 'is', coordinates: [-19.0208, 64.9631] },
            276: { name: 'Albania', iso2: 'al', coordinates: [20.1683, 41.1533] },
            278: { name: 'Malta', iso2: 'mt', coordinates: [14.3754, 35.9375] },
            280: { name: 'Cyprus', iso2: 'cy', coordinates: [33.4299, 35.1264] },
            282: { name: 'Georgia', iso2: 'ge', coordinates: [43.3569, 42.3154] },
            283: { name: 'Armenia', iso2: 'am', coordinates: [45.0382, 40.0691] },
            284: { name: 'Bulgaria', iso2: 'bg', coordinates: [25.4858, 42.7339] },
            286: { name: 'Turkey', iso2: 'tr', coordinates: [35.2433, 38.9637] },
            288: { name: 'Faroe Islands (Denmark)', iso2: 'fo', coordinates: [-6.9118, 62.0079] },
            290: { name: 'Greenland (Denmark)', iso2: 'gl', coordinates: [-42.6043, 71.7069] },
            292: { name: 'San Marino', iso2: 'sm', coordinates: [12.4578, 43.9333] },
            293: { name: 'Slovenia', iso2: 'si', coordinates: [14.9955, 46.1512] },
            294: { name: 'Republic of Macedonia', iso2: 'mk', coordinates: [21.7453, 41.9981] },
            295: { name: 'Liechtenstein', iso2: 'li', coordinates: [9.5215, 47.166] },
            297: { name: 'Montenegro', iso2: 'me', coordinates: [19.3744, 42.7087] },
            334: { name: 'Mexico', iso2: 'mx', coordinates: [-102.5528, 23.6345] },
            450: { name: 'South Korea', iso2: 'kr', coordinates: [127.7669, 35.9078] }
        },
    networkCodesWhiteList: [235, 225, 247, 219, 293, 295, 231, 280, 234, 202, 246, 268, 214, 248, 204, 226, 238, 216, 206, 228, 208, 270, 260, 272, 284, 262, 240, 230, 242, 286, 232, 244, 222, 450],
};

